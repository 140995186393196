import { BonhamsCookies } from '@nx/global-types'
import { logger } from '@nx/utils/logger'

import { getFollowedByType } from '../followApi'
import { FollowType, FollowedLotsResponse } from '../get-user-follows.types'

export async function getFollowedLots(
  userId: number,
  cookies: BonhamsCookies,
  baseUrl: string
): Promise<Record<number | string, number[]>> {
  try {
    const response = await getFollowedByType<FollowedLotsResponse>({
      userId,
      type: FollowType.lot,
      cookies,
      baseURL: baseUrl,
    })

    // Return an object where the key is the Auction ID and the value is an array of Lot IDs.
    return response.data.reduce(
      (accumulator: Record<number | string, number[]>, item) => {
        if (accumulator[item.auctionId]) {
          accumulator[item.auctionId].push(item.iSaleItemNo)
        } else {
          accumulator[item.auctionId] = [item.iSaleItemNo]
        }

        return accumulator
      },
      {}
    )
  } catch (error) {
    logger.error(error, `Could not fetch lot likes for user ${userId}`)
    return {}
  }
}
