import { UseQueryResult, useQueries } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { FollowType } from '../get-user-follows.types'
import { getFollowedArtists } from '../getFollowedArtists'
import { getFollowedAuctions } from '../getFollowedAuctions'
import { getFollowedDepartments } from '../getFollowedDepartments'
import { getFollowedLots } from '../getFollowedLots'

export function useGetAllFollowed({
  userId = 0,
  baseUrl,
}: {
  userId?: number
  baseUrl: string
}): UseGetAllFollowedResponse {
  const cookies = Cookies.get()

  const queryResults = useQueries<(number | string)[]>({
    queries: [
      {
        queryKey: [`followed-${FollowType.artist}s`, userId],
        queryFn: () => getFollowedArtists(userId, cookies, baseUrl),
        enabled: Boolean(userId),
      },
      {
        queryKey: [`followed-${FollowType.auction}s`, userId],
        queryFn: () => getFollowedAuctions(userId, cookies, baseUrl),
        enabled: Boolean(userId),
      },
      {
        queryKey: [`followed-${FollowType.department}s`, userId],
        queryFn: () => getFollowedDepartments(userId, cookies, baseUrl),
        enabled: Boolean(userId),
      },
      {
        queryKey: [`followed-${FollowType.lot}s`, userId],
        queryFn: () => getFollowedLots(userId, cookies, baseUrl),
        enabled: Boolean(userId),
      },
    ],
  })

  function getResultObject<T>({
    status,
    data,
    isFetching,
    error,
  }: UseQueryResult) {
    return {
      status,
      data: data as T,
      isFetching,
      error,
    }
  }

  return {
    artists: getResultObject<number[]>(queryResults[0]),
    auctions: getResultObject<number[]>(queryResults[1]),
    departments: getResultObject<string[]>(queryResults[2]),
    lots: getResultObject<Record<number | string, number[]>>(queryResults[3]),
  }
}

export interface UseGetAllFollowedResponse {
  artists: FollowedQueryResult<number[]>
  auctions: FollowedQueryResult<number[]>
  departments: FollowedQueryResult<string[]>
  lots: FollowedQueryResult<Record<number | string, number[]>>
}

export interface FollowedQueryResult<T> {
  status: UseQueryResult['status']
  data: T
  isFetching: UseQueryResult['isFetching']
  error: UseQueryResult['error']
}
