import { BonhamsCookies } from '@nx/global-types'
import { logger } from '@nx/utils/logger'

import { getFollowedByType } from '../followApi'
import { FollowType, FollowedAuctionsResponse } from '../get-user-follows.types'

export async function getFollowedAuctions(
  userId: number,
  cookies: BonhamsCookies,
  baseUrl: string
): Promise<number[]> {
  try {
    const response = await getFollowedByType<FollowedAuctionsResponse>({
      userId,
      type: FollowType.auction,
      cookies,
      baseURL: baseUrl,
    })

    return response.data.map((item) => item.iSaleNo)
  } catch (error) {
    logger.error(error, `Could not fetch auction likes for user ${userId}`)
    return []
  }
}
